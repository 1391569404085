<template>
  <q-btn
    no-caps
    unelevated
    icon="event"
    text-color="grey-8"
    align="left"
    class="text-weight-regular"
    :style="{
      background: 'rgba(0,0,0,0.05)'
    }"
  >
    <span class="q-ml-sm text-black">{{ selectorButtonLabel }}</span>

    <q-badge
      v-show="selectedState.preset.length > 0"
      outline
      color="grey-7"
      class="q-ml-md"
    >
      {{ getPresetLabel(selectedState.preset) }}
    </q-badge>

    <q-popup-proxy
      transition-show="jump-down"
      transition-hide="jump-up"
      :offset="[0, -80]"
      ref="dateRangeSelectorPopup"
      @before-hide="onPopupHide"
    >
      <div class="row">
        <div class="col" :style="{ 'z-index': 11 }">
          <q-date
            v-model="popupState.range"
            range
            mask="YYYY-MM-DD"
            :today-btn="true"
            landscape
          >
            <div class="flex justify-between items-center">
              <q-btn
                flat
                label="Cancel"
                color="subtle"
                @click="
                  () => {
                    cancelPopupChanges();
                  }
                "
              />
              <q-btn
                flat
                label="Update"
                color="primary"
                @click="
                  () => {
                    savePopupChanges();
                  }
                "
              />
            </div>
          </q-date>
        </div>
        <div class="bg-grey-1" style="min-width: 130px">
          <p class="text-grey-6 text-bold q-ml-md q-pt-md q-pb-sm">
            Date Presets
          </p>
          <q-list dense>
            <q-item
              v-for="presetKey in Object.keys(presets)"
              :key="presetKey"
              :label="getPresetLabel(presetKey)"
              :clickable="true"
              @click="
                () => {
                  setPopupPreset(presetKey);
                  savePopupChanges();
                }
              "
              :focused="presetKey === popupState.preset"
              :active="presetKey === popupState.preset"
            >
              <q-item-section>
                <span style="font-size: 90%">{{
                  getPresetLabel(presetKey)
                }}</span>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </q-popup-proxy>
  </q-btn>
</template>

<script>
import moment from "moment";

export default {
  name: "DateRangeSelector",
  props: {
    initialStartDate: {
      type: String,
      default: moment().format("YYYY-MM-DD")
    },
    initialEndDate: {
      type: String,
      default: moment().format("YYYY-MM-DD")
    },
    initialPreset: {
      type: String,
      default: ""
    },
    urlState: {
      type: Boolean,
      default: false
    }
  },
  emits: ["update"],
  data() {
    return {
      selectorButtonLabel: "",
      selectedState: {
        preset: "",
        range: {
          from: this.initialStartDate,
          to: this.initialEndDate
        }
      },
      popupState: {
        preset: "",
        range: this.formatPopupRange(this.initialStartDate, this.initialEndDate)
      },
      urlStateRefreshInterval: null,
      presets: {
        today: {
          from: moment().format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD")
        },
        yesterday: {
          from: moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        },
        last_7_days: {
          from: moment()
            .subtract(7, "days")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        },
        last_14_days: {
          from: moment()
            .subtract(14, "days")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        },
        last_30_days: {
          from: moment()
            .subtract(30, "days")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "days")
            .format("YYYY-MM-DD")
        },
        this_week: {
          from: moment()
            .startOf("week")
            .format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD")
        },
        last_week: {
          from: moment()
            .subtract(7, "days")
            .startOf("week")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(7, "days")
            .endOf("week")
            .format("YYYY-MM-DD")
        },
        this_month: {
          from: moment()
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: moment().format("YYYY-MM-DD")
        },
        last_month: {
          from: moment()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          to: moment()
            .subtract(1, "month")
            .endOf("month")
            .format("YYYY-MM-DD")
        }
      }
    };
  },
  watch: {
    "popupState.range"() {
      this.refreshPreset("popup");
    }
  },
  mounted() {
    if (
      this.initialPreset.length > 0 &&
      typeof this.presets[this.initialPreset] !== "undefined"
    ) {
      let thisPreset = this.presets[this.initialPreset];
      this.selectedState.range = thisPreset;
      this.popupState.range = this.formatPopupRange(
        thisPreset.from,
        thisPreset.to
      );
    }

    this.refreshPreset("selected");
    this.refreshPreset("popup");
    this.setSelectorButtonLabel();

    if (this.urlState) {
      this.saveUrlState();

      // If using urlState, then reload the entire page if the current date has changed, to force state refresh w/ new day
      clearInterval(this.urlStateRefreshInterval);
      let loadDate = moment().format("YYYY-MM-DD");
      this.urlStateRefreshInterval = setInterval(() => {
        if (loadDate != moment().format("YYYY-MM-DD")) {
          window.location.reload();
        }
      }, 300000);
    }
  },
  beforeUnmount() {
    if (this.urlState) {
      clearInterval(this.urlStateRefreshInterval);
    }
  },
  methods: {
    refreshPreset(state) {
      let rangeToCompare = null;
      if (state === "popup") {
        if (
          !this.popupState.range ||
          typeof this.popupState.range !== "object"
        ) {
          rangeToCompare = {
            from: this.popupState.range ?? "0",
            to: this.popupState.range ?? "0"
          };
        } else {
          rangeToCompare = this.popupState.range;
        }
      } else {
        rangeToCompare = this.selectedState.range;
      }

      let presetMatchFound = false;
      Object.entries(this.presets).some(preset => {
        let presetKey = preset[0];
        let presetRange = preset[1];

        if (
          presetRange.from === rangeToCompare.from &&
          presetRange.to === rangeToCompare.to
        ) {
          if (state === "popup") {
            this.popupState.preset = presetKey;
          } else {
            this.selectedState.preset = presetKey;
          }
          presetMatchFound = true;
          return true;
        }
      });

      if (!presetMatchFound) {
        if (state === "popup") {
          this.popupState.preset = "";
        } else {
          this.selectedState.preset = "";
        }
      }
    },
    setPopupPreset(presetKey) {
      this.popupState.preset = presetKey;
      let thisPresetRange = this.presets[presetKey];
      this.popupState.range = this.formatPopupRange(
        thisPresetRange.from,
        thisPresetRange.to
      );
    },
    setSelectorButtonLabel() {
      this.selectorButtonLabel =
        this.selectedState.range.from === this.selectedState.range.to
          ? moment(this.selectedState.range.to, "YYYY-MM-DD").format("ll")
          : moment(this.selectedState.range.from, "YYYY-MM-DD").format("ll") +
            " - " +
            moment(this.selectedState.range.to, "YYYY-MM-DD").format("ll");
    },
    savePopupChanges() {
      if (!this.popupState.range) return;

      if (typeof this.popupState.range !== "object") {
        this.selectedState.range = {
          from: this.popupState.range,
          to: this.popupState.range
        };
      } else {
        this.selectedState.range = this.popupState.range;
      }
      this.selectedState.preset = this.popupState.preset;
      this.setSelectorButtonLabel();
      this.$refs.dateRangeSelectorPopup.hide();

      if (this.urlState) {
        this.saveUrlState();
      }

      this.$emit("update", {
        startDate: this.selectedState.range.from,
        endDate: this.selectedState.range.to,
        preset: this.selectedState.preset
      });
    },
    cancelPopupChanges() {
      this.popupState.range = this.formatPopupRange(
        this.selectedState.range.from,
        this.selectedState.range.to
      );
      this.popupState.preset = this.selectedState.preset;
      this.setSelectorButtonLabel();
      this.$refs.dateRangeSelectorPopup.hide();
    },
    onPopupHide() {
      if (
        (typeof this.popupState.range !== "object" &&
          this.popupState.range !== this.selectedState.range.from) ||
        (this.popupState.range.from !== this.selectedState.range.from &&
          this.popupState.range.to !== this.selectedState.range.to)
      ) {
        this.popupState.range = this.formatPopupRange(
          this.selectedState.range.from,
          this.selectedState.range.to
        );
        this.popupState.preset = this.selectedState.preset;
      }
    },
    formatPopupRange(startDate, endDate) {
      if (startDate === endDate) {
        return startDate;
      }
      return {
        from: startDate,
        to: endDate
      };
    },
    getPresetLabel(presetKey) {
      return this.capitalizeText(presetKey).replaceAll("_", " ");
    },
    saveUrlState() {
      let urlParams = new URLSearchParams(window.location.search);
      if (this.selectedState.preset.length > 0) {
        urlParams.set("date_preset", this.selectedState.preset);
        urlParams.delete("start_date");
        urlParams.delete("end_date");
      } else {
        urlParams.set("start_date", this.selectedState.range.from);
        urlParams.set("end_date", this.selectedState.range.to);
        urlParams.delete("date_preset");
      }
      window.history.replaceState(
        history.state,
        "",
        "?" + urlParams.toString()
      );
    }
  }
};
</script>

<style scoped lang="scss"></style>
