<template>
  <div class="network-performance">
    <div v-if="!authorized">
      <q-dialog v-model="passcodeDialog" persistent>
        <q-card style="min-width: 350px; border: none !important;">
          <q-bar class="bg-black q-py-lg justify-center">
            <img
              src="@/assets/images/logos/jumpfeed-full-light.svg"
              style="width: 120px;"
            />
          </q-bar>
          <q-card-section>
            <p>Enter your passcode to access reporting.</p>
          </q-card-section>

          <q-card-section class="q-pt-none">
            <q-input dense v-model="passcode" autofocus type="password" label="Passcode" @keyup.enter="authorizePasscode" :error="passcodeError" />
          </q-card-section>

          <q-card-actions align="right" class="text-primary">
            <q-btn color="primary" label="Submit" @click="authorizePasscode" />
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
    <q-layout v-else view="hHr Lpr lFr" class="dashboard">
      <q-header elevated class="bg-dark text-white">
        <q-toolbar class="q-px-md q-gutter-x-md">
          <q-toolbar-title class="flex items-center q-ml-lg">
            <router-link to="/" class="flex q-ml-sm">
              <img
                src="@/assets/images/logos/jumpfeed-full-light.svg"
                style="width: 125px; align-self: flex-start;"
              />
            </router-link>
            <q-separator vertical color="subtle" class="q-mx-md" />
            <p style="font-size: 13px">External Reporting</p>
          </q-toolbar-title>
        </q-toolbar>
      </q-header>

      <q-page-container class="q-px-lg">
        <div class="row items-center justify-start q-my-lg">
          <DateRangeSelector
            :initial-start-date="startDate"
            :initial-end-date="endDate"
            @update="
              dates => {
                updateDateRange(dates);
                fetchReport();
              }
            "
            url-state
          />
        </div>

        <!-- TODO: Change component functionality to the below. Start leveraging v-model where needed on components -->
        <!-- <DateRangeSelector
          v-model:range="range"
        /> -->

        <DataTable
          :table-key="tableKey"
          row-key="id"
          title="Network Performance"
          :columns="columns"
          :rows="rows"
          :totals-row="totalsRow"
          :loading="loading"
          :pagination="pagination"
          :rows-per-page-options="[25, 50]"
          :pivot-table="true"
          :downloadable="false"
          :refreshable="true"
          :customizable="true"
          :filterable="true"
          :server-side="true"
          :searchable="false"
          :disable-state="false"
          :dimension-filter-options-by-column="dimensionFilterOptionsByColumn"
          :time-last-updated="reportingTimeLastUpdated"
          @update-rows="val => handleUpdateRowsEvent(val)"
        />
      </q-page-container>
    </q-layout>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DateRangeSelector from "@/components/UI/DateRangeSelector";
import DataTable from "@/components/DataTable";
import DataTableMixin from "@/mixins/DataTableMixin";

moment.locale("en");

export default {
  name: "NetworkPerformanceNew",
  mixins: [DataTableMixin],
  components: {
    DataTable,
    DateRangeSelector
  },
  data() {
    return {
      authorized: false,
      passcodeDialog: true,
      passcode: "",
      passcodeError: false,
      publisherAccountId: 0,
      tableKey: "networkPerformance",
      loading: true,
      refreshInterval: null,
      startDate: this.$route.query.start_date
        ? this.$route.query.start_date
        : moment().format("YYYY-MM-DD"),
      endDate: this.$route.query.end_date
        ? this.$route.query.end_date
        : moment().format("YYYY-MM-DD"),
      datePreset: this.$route.query.date_preset
        ? this.$route.query.date_preset
        : "",
      pagination: {
        sortBy: "media_spend",
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 1000
      },
      rows: [],
      columns: [
        {
          name: "date",
          label: "Date",
          field: "date",
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "hour",
          label: "Hour of Day (CT)",
          field: "hour",
          format: val => (!val ? "-" : val + ":00"),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "brand",
          label: "Brand",
          field: "brand_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "brand_name",
          type: "dimension"
        },
        {
          name: "advertiser_business",
          label: "Advertiser Business",
          field: "advertiser_business_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "advertiser_business_name",
          type: "dimension",
          visible: false
        },
        {
          name: "advertiser_account",
          label: "Advertiser Account",
          field: "advertiser_account_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "advertiser_account_name",
          type: "dimension",
          visible: false
        },
        {
          name: "publisher_component",
          label: "Publisher Component",
          field: "publisher_component_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "publisher_component_name",
          type: "dimension",
          visible: false
        },
        {
          name: "publisher_component_type",
          label: "Publisher Component Type",
          field: "publisher_component_type",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "publisher_component_type",
          type: "dimension",
          visible: false
        },
        {
          name: "publisher_component_placement",
          label: "Publisher Component Placement",
          field: "publisher_component_placement",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "publisher_component_placement",
          type: "dimension",
          visible: false
        },
        {
          name: "vertical",
          label: "Vertical",
          field: "vertical_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "vertical_name",
          type: "dimension",
          visible: false
        },
        {
          name: "product_category",
          label: "Category",
          field: "product_category",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "channel",
          label: "Channel",
          field: "channel_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "channel_name",
          type: "dimension",
          visible: false
        },
        {
          name: "campaign_type",
          label: "Campaign Type",
          field: "campaign_type_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "campaign_type_name",
          type: "dimension",
          visible: false
        },
        {
          name: "offer_tag",
          label: "Offer Tag",
          field: "offer_tag",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "offer",
          label: "Offer",
          field: "offer_name",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "offer_name",
          type: "dimension",
          visible: false
        },
        {
          name: "split_parameter",
          label: "Split Parameter",
          field: "split_parameter",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "keyword",
          label: "Keyword",
          field: "keyword",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "keyword_modifier",
          label: "Keyword Modifier",
          field: "keyword_modifier",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "product",
          label: "Product",
          field: "product_id",
          format: val => (!val ? "-" : val),
          sortable: true,
          sortField: "product_id",
          type: "dimension",
          visible: false
        },
        {
          name: "device",
          label: "Device",
          field: "device",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "device_type",
          label: "Device Type",
          field: "device_type",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "device_manufacturer",
          label: "Device Manufacturer",
          field: "device_manufacturer",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "device_model",
          label: "Device Model",
          field: "device_model",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "operating_system",
          label: "Operating System",
          field: "operating_system",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "browser",
          label: "Browser",
          field: "browser",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "browser_type",
          label: "Browser Type",
          field: "browser_type",
          format: val => (!val ? "-" : val),
          sortable: true,
          type: "dimension",
          visible: false
        },
        {
          name: "media_spend",
          label: "Media Spend",
          field: "media_spend",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "media_impressions",
          label: "Media Impressions",
          field: "media_impressions",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "media_cpm",
          label: "Media CPM",
          field: "media_cpm",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "media_clicks",
          label: "Media Clicks",
          field: "media_clicks",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "media_cpc",
          label: "Media CPC",
          field: "media_cpc",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "media_ctr",
          label: "Media CTR",
          field: "media_ctr",
          sortable: true,
          sort: (a, b) => this.sortPercentage(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "srp_visitors",
          label: "SRP Visitors",
          field: "srp_visitors",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "no_content_loaded_visitors",
          label: "No Content Visitors",
          field: "no_content_loaded_visitors",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "no_content_loaded_visitor_rate",
          label: "Fail Rate",
          field: "no_content_loaded_visitor_rate",
          sortable: true,
          sort: (a, b) => this.sortPercentage(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "cost_per_srp_visitor",
          label: "Cost/SRP Visitor",
          field: "cost_per_srp_visitor",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "srp_conversion_rate",
          label: "SRP Conv. Rate",
          field: "srp_conversion_rate",
          sortable: true,
          sort: (a, b) => this.sortPercentage(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "conversions",
          label: "Conversion Events",
          field: "conversions",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "payable_conversions",
          label: "Payable Conversions",
          field: "payable_conversions",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "unique_content_conversions",
          label: "Unique Content Conversions",
          field: "unique_content_conversions",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "unique_conversions",
          label: "Unique Conversions",
          field: "unique_conversions",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "%_total_conversions",
          label: "% Total Conv.",
          field: "%_total_conversions",
          sortable: false,
          filterable: false,
          sort: (a, b) => this.sortPercentage(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "payable_conversions_per_srp_visitor",
          label: "Payable Conv./SRP Visitor",
          field: "payable_conversions_per_srp_visitor",
          sortable: true,
          sort: (a, b) => this.sortDecimal(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "cost_per_conversion",
          label: "Cost/Conv.",
          field: "cost_per_conversion",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "revenue_per_conversion",
          label: "Revenue/Conv.",
          field: "revenue_per_conversion",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "revenue_per_srp_visitor",
          label: "Revenue/SRP Visitor",
          field: "revenue_per_srp_visitor",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "revenue",
          label: "Revenue",
          field: "revenue",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "profit",
          label: "Profit",
          field: "profit",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number",
          classes: row =>
            "profit" in row && row.profit.includes("-") && row.profit.length > 1
              ? "text-red text-bold"
              : ""
        },
        {
          name: "profit_margin",
          label: "Profit Margin",
          field: "profit_margin",
          sortable: true,
          sort: (a, b) => this.sortPercentage(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "roas",
          label: "ROAS",
          field: "roas",
          sortable: true,
          sort: (a, b) => this.sortDecimal(a, b),
          type: "metric",
          metricType: "number",
          classes: row =>
            "roas" in row && parseFloat(row.roas) < 1
              ? "text-red text-bold"
              : ""
        },
        {
          name: "business_conversions",
          label: "Business Conversions",
          field: "business_conversions",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "business_conversions_percent_diff",
          label: "Business Conversions % Diff.",
          field: row =>
            "business_conversions_percent_diff" in row
              ? row.business_conversions_percent_diff
              : "-",
          sortable: true,
          sort: (a, b) => this.sortPercentage(a, b),
          type: "metric",
          metricType: "number",
          classes: row =>
            "business_conversions_percent_diff" in row &&
            row.business_conversions_percent_diff.includes("-") &&
            row.business_conversions_percent_diff.length > 1
              ? "text-red text-bold"
              : ""
        },
        {
          name: "business_revenue_per_conversion",
          label: "Business Revenue/Conv.",
          field: "business_revenue_per_conversion",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "business_revenue",
          label: "Business Revenue",
          field: "business_revenue",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "business_revenue_per_srp_visitor",
          label: "Business Revenue/SRP Visitor",
          field: "business_revenue_per_srp_visitor",
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number"
        },
        {
          name: "business_profit",
          label: "Business Profit",
          field: row => ("business_profit" in row ? row.business_profit : "-"),
          sortable: true,
          sort: (a, b) => this.sortMoney(a, b),
          type: "metric",
          metricType: "number",
          classes: row =>
            "business_profit" in row &&
            row.business_profit.includes("-") &&
            row.business_profit.length > 1
              ? "text-red text-bold"
              : ""
        },
        {
          name: "business_profit_margin",
          label: "Business Profit Margin",
          field: row =>
            "business_profit_margin" in row ? row.business_profit_margin : "-",
          sortable: true,
          sort: (a, b) => this.sortPercentage(a, b),
          type: "metric",
          metricType: "number",
          classes: row =>
            "business_profit_margin" in row &&
            row.business_profit_margin.includes("-") &&
            row.business_profit_margin.length > 1
              ? "text-red text-bold"
              : ""
        },
        {
          name: "business_roas",
          label: "Business ROAS",
          field: row => ("business_roas" in row ? row.business_roas : "-"),
          sortable: true,
          sort: (a, b) => this.sortDecimal(a, b),
          type: "metric",
          metricType: "number",
          classes: row =>
            "business_roas" in row && parseFloat(row.business_roas) < 1
              ? "text-red text-bold"
              : ""
        },
        {
          name: "business_quality_score",
          label: "Business Quality Score",
          field: "business_quality_score",
          sortable: true,
          sort: (a, b) => this.sortDecimal(a, b),
          type: "metric",
          metricType: "number"
        }
      ],
      totalsRow: {},
      dimensionFilterOptionsByColumn: {},
      reportingTimeLastUpdated: "",
      pendingFetchReportRequests: []
    };
  },
  computed: {},
  beforeMount() {
    if (this.datePreset.length > 0) {
      let dateRange = this.getDatePresetRange(this.datePreset);
      this.startDate = dateRange.from;
      this.endDate = dateRange.to;
    }

    let urlParamDataTableState = this.loadDataTableStateFromURLParam(
      this.tableKey,
      this.columns
    );

    this.columns = urlParamDataTableState.columns;

    if (Object.keys(urlParamDataTableState.pagination).length > 0) {
      this.pagination = urlParamDataTableState.pagination;
    }
  },
  mounted() {
    let urlParams = new URLSearchParams(window.location.search);
    if(urlParams.get("p")) {
      this.passcode = urlParams.get("p");
      this.authorizePasscode();
    }
  },
  beforeUnmount() {
    clearInterval(this.refreshInterval);
  },
  methods: {
    authorizePasscode() {
      this.passcodeError = false;
      if(this.passcode == "3092") {
        this.publisherAccountId = 10003;
        this.authorized = true;
        this.passcodeDialog = false;
        this.fetchReport();
      }
      else if(this.passcode == "7962") {
        this.publisherAccountId = 10002;
        this.authorized = true;
        this.passcodeDialog = false;
        this.fetchReport();
      }
      else {
        this.passcodeError = true;
      }
    },
    updateDateRange(dates = null) {
      if (dates) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
      }
    },
    fetchReport(dataTableState = null) {
      this.loading = true;

      clearInterval(this.refreshInterval);
      this.refreshInterval = setInterval(
        () => this.fetchReport(dataTableState),
        300000
      );

      // If we dont have a dataTableState (passed in events emitted by the datatable), then fallback to default initial request.
      if (!dataTableState) {
        dataTableState = {
          columns: this.columns,
          pagination: this.pagination,
          searchFilter: ""
        };
      }

      // Abort any existing requests
      if (this.pendingFetchReportRequests.length > 0) {
        this.pendingFetchReportRequests.forEach(controller => {
          controller.abort();
        });
        this.pendingFetchReportRequests = [];
      }
      let controller = new AbortController();
      let { signal } = controller;
      this.pendingFetchReportRequests.push(controller);

      // Remap our sort field (ex, brand vs brand_name)
      let sortField = dataTableState.pagination.sortBy;
      let sortFieldColumn = dataTableState.columns.filter(
        col => col.name === sortField
      );
      if (
        sortFieldColumn.length > 0 &&
        sortFieldColumn[0].sortField &&
        sortFieldColumn[0].sortField.length > 0
      ) {
        sortField = sortFieldColumn[0].sortField;
      }

      // Configure sorting array. (additional default sorting for all other dimensions, in order to maintain uniqueness in ordering across pages)
      let sortingArray = [
        {
          field: sortField,
          direction: dataTableState.pagination.descending ? "desc" : "asc"
        }
      ];
      dataTableState.columns
        .filter(
          fc => fc.visible && fc.type === "dimension" && fc.field !== sortField
        )
        .forEach(column => {
          sortingArray.push({
            field: column.sortField ? column.sortField : column.name,
            direction: "asc"
          });
        });

      // Fetch Report
      axios({
        url: "https://api-v2.jumpfeed.com/internal/reporting",
        method: "POST",
        signal: signal,
        data: {
          report_payload: {
            start_date: this.startDate,
            end_date: this.endDate,
            options: {
              pagination: {
                per_page: dataTableState.pagination.rowsPerPage,
                page: dataTableState.pagination.page
              },
              sorting: sortingArray,
              include_results_name_fields: true,
              include_results_id_fields: true,
              dimension_key_indexed_results: true,
              include_time_last_updated: true,
              format_results: true,
              enable_drilldown_mode: true,
              include_totals: true,
              include_query_profiles: true
            },
            dimensions: {
              publisher_account: {
                filter: {
                  in: [this.publisherAccountId]
                }
              },
              ...dataTableState.columns
                .filter(c => c.type === "dimension" && c.visible)
                .reduce(
                    (dimensions, dimensionColumn) =>
                        Object.assign(dimensions, {
                          [dimensionColumn.name]:
                              dimensionColumn.filterValues &&
                              dimensionColumn.filterValues.in.length > 0
                                  ? {
                                    filter: {in: dimensionColumn.filterValues.in}
                                  }
                                  : []
                        }),
                    {}
                )
            },
            metrics: dataTableState.columns
              .filter(c => c.type === "metric" && c.visible)
              .reduce(
                (metrics, metricColumn) =>
                  Object.assign(metrics, {
                    [metricColumn.name]:
                      metricColumn.filterValues &&
                      metricColumn.activeFilterCount > 0
                        ? {
                            filter: {
                              ...(metricColumn.filterValues.gt.length > 0 && {
                                gt: metricColumn.filterValues.gt
                              }),
                              ...(metricColumn.filterValues.lt.length > 0 && {
                                lt: metricColumn.filterValues.lt
                              })
                            }
                          }
                        : []
                  }),
                {}
              )
          }
        }
      })
        .then(response => {
          let report = "data" in response ? response.data.data : {};
          let reportMetadata = "metadata" in report ? report.metadata : {};

          this.rows = "rows" in report ? Object.values(report.rows) : [];

          let dimensionFacets =
            "dimension_facets" in reportMetadata
              ? reportMetadata.dimension_facets
              : {};
          if (dimensionFacets && dimensionFacets.hour) {
            dimensionFacets.hour = dimensionFacets.hour.map(hourFacet => {
              hourFacet.label = hourFacet.label + ":00";
              return hourFacet;
            });
          }
          this.dimensionFilterOptionsByColumn = dimensionFacets;

          this.pagination.rowsNumber =
            "total_rows" in reportMetadata
              ? parseInt(reportMetadata.total_rows)
              : 0;

          this.totalsRow =
            "totals" in reportMetadata ? reportMetadata.totals : {};

          this.reportingTimeLastUpdated =
            "time_last_updated" in reportMetadata
              ? reportMetadata.time_last_updated
              : "";

          this.loading = false;
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            return;
          }
          this.loading = false;
        });
    },
    handleUpdateRowsEvent(columns) {
      this.fetchReport(columns);
    }
  }
};
</script>

<style scoped lang="scss"></style>
