import LZString from "lz-string";

export default {
  data() {
    return {};
  },
  methods: {
    loadDataTableStateFromURLParam(
      tableKey,
      columns,
      setColumnFilters = true,
      setColumnVisibility = true
    ) {
      let dataTableState = {
        columns: columns,
        pagination: {},
        searchFilter: ""
      };

      let urlParams = new URLSearchParams(window.location.search);
      let urlParamTableState = urlParams.get(tableKey + "_state");

      if (urlParamTableState) {
        urlParamTableState = JSON.parse(
          LZString.decompressFromEncodedURIComponent(urlParamTableState)
        );

        let urlParamColumnsByName = this.convertToObjectById(
          urlParamTableState.columns,
          "name"
        );

        let columnNames = columns.map(column => column.name);

        // Only init from url param state if the columns match
        if (
          Object.keys(urlParamColumnsByName).every(urlParamColumnName =>
            columnNames.includes(urlParamColumnName)
          )
        ) {
          dataTableState.pagination = urlParamTableState.pagination;
          dataTableState.searchFilter = urlParamTableState.searchFilter;

          columns = columns.map(column => {
            if (typeof urlParamColumnsByName[column.name] === "undefined")
              return column;

            if (setColumnVisibility) {
              column.visible = urlParamColumnsByName[column.name].visible;
            }

            if (setColumnFilters) {
              column.filterValues = {
                in: [],
                notIn: [],
                gt: "",
                lt: ""
              };
              column.activeFilterCount = 0;

              if (urlParamColumnsByName[column.name].filterValues) {
                Object.keys(
                  urlParamColumnsByName[column.name].filterValues
                ).forEach(filterValueOperator => {
                  column.filterValues[filterValueOperator] =
                    urlParamColumnsByName[column.name].filterValues[
                      filterValueOperator
                    ];
                });
                column.activeFilterCount = Object.values(
                  column.filterValues
                ).reduce(
                  (partialCount, filterValue) =>
                    partialCount +
                    (Array.isArray(filterValue)
                      ? filterValue.length
                      : filterValue.length > 0
                      ? 1
                      : 0),
                  0
                );
              }
            }

            return column;
          });
        }
      }

      return dataTableState;
    }
  }
};
